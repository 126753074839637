.requests-box {
    background: $color-extra-light-grey;
    ;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    border-radius: 20px;
    position: relative;

    @include lg {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
}

.requests-box__logout {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    z-index: 2;
}

.request-box__header {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.requests-box__table {
    width: 80%;
    background: #fff;
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
    border-top: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
}

.requests-box__buddy {
    border-radius: 15px;
    box-shadow: 0 0 5px #e1e1e1;
    padding: 18px;
    background: #fff;
}

.requests-box__aproval {
    background: #fff;
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
    padding: 1em;
}

.requests-box__buddy__details {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    text-align: center;
}

.requests-box__table__td {
    // border-top: 2px solid $color-white;
    // border-bottom: 2px solid $color-white;
    padding: 1em;
}

.requests-box__table__td-master {
    background: lightblue;
    border-radius: 10px;
    margin-left: 10px;
}

.requests-box__table__td-status-1 {
    background: linear-gradient(to right, #fcfbdc, #dcfce7);
    padding: 6px;
    border-radius: 15px;
    color: #655916;
    font-size: 0.75rem;
    font-weight: 700;
    width: 65px;
    text-align: center;
}

.requests-box__table__td-status-2 {
    background: #bbffd2;
    padding: 6px;
    border-radius: 15px;
    color: #166534;
    font-size: 0.75rem;
    font-weight: 700;
    width: 65px;
    text-align: center;
}

.requests-box__table__td-status-3 {
    background: #fce1dc;
    padding: 6px;
    border-radius: 15px;
    color: #651b16;
    font-size: 0.75rem;
    font-weight: 700;
    width: 65px;
    text-align: center;
}

//obrisati status-0 po završetku
.requests-box__table__td-status-4,
.requests-box__table__td-status-0 {
    background: #fcfbdc;
    padding: 6px;
    border-radius: 15px;
    color: #655916;
    font-size: 0.75rem;
    font-weight: 700;
    width: 65px;
    text-align: center;
}

.requests-box__table__td__details {
    text-decoration: none;
    color: $color-primary;
}

.requests-box__table__td__status-icon {
    width: 20px;
}

.requests-box__table__td__approval-icon {
    width: 25px;
    cursor: pointer;
}

.requests-box__table__td__reminder-icon {
    width: 30px;
    cursor: pointer;
}

.requests-box__table__td__delete-employee {
    width: 30px;
    cursor: pointer;
}