// margins

@for $i from 0 through 10 {
    .margin-bottom-#{$i} {
        margin-bottom: $i*10px;
    }
}

@for $i from 0 through 10 {
    .margin-top-#{$i} {
        margin-top: $i*10px;
    }
}

@for $i from 0 through 10 {
    .margin-left-#{$i} {
        margin-left: $i*10px;
    }
}

@for $i from 0 through 10 {
    .margin-right-#{$i} {
        margin-right: $i*10px;
    }
}

// paddings

@for $i from 0 through 10 {
    .padding-left-#{$i} {
        padding-left: $i*10px;
    }
}

@for $i from 0 through 10 {
    .padding-right-#{$i} {
        padding-right: $i*10px;
    }
}

@for $i from 0 through 10 {
    .padding-#{$i} {
        padding: $i*10px !important;
    }
}

@for $i from 0 through 8 {
    .font-weight-#{$i} {
        font-weight: $i*100;
    }
}

// utilityes

.hide {
    display: none !important;
}

.text-white {
    color: $color-white;
}

.text-danger {
    color: $color-danger;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.relative-element {
    position: relative;
}

.font-12 {
    font-size: 0.75rem;
}

.font-13 {
    font-size: 0.8125rem;
}

.hide-small {
    @include sm-max {
        display: none
    }
}

.full-width {
    width: 100%;
}

// components

.button {
    text-decoration: none;
    font-family: ManropeRegular, sans-serif;
    font-size: 1.0625rem;
    padding: 14px;
    background-color: $color-primary;
    border: none;
    border-radius: 7px;
    cursor: pointer;

    &:disabled {
        background-color: $color-light-grey;
        cursor: default;
    }
}

.button-back {
    display: none;

    @include lg {
        display: block;
        position: absolute;
        align-self: flex-start;
        height: 50px;
        width: 50px;
        margin-top: 10px;
        margin-left: 20px;
        position: fixed;
    }
}

.dropdown {
    border-radius: 32px;
    border-style: solid;
    padding: 8px 24px;
}

.description-text {
    line-height: 1.2;
    margin-left: 30px;
    margin-right: 30px;

    @include sm {
        margin-left: 50px;
        margin-right: 50px;
    }

    @include md {
        margin-left: 80px;
        margin-right: 80px;
    }

    @include lg {
        margin-left: 100px;
        margin-right: 100px;
    }
}

// Ant design overrides
.ant-btn-primary {
    background-color: $color-primary;
}