@import 'base/typography';

@import 'abstracts/variables';
@import 'abstracts/breakpoints';
@import 'abstracts/mixins';
@import 'abstracts/utilityes';

@import 'components/tables';
@import 'layout/forms';

@font-face {
    font-family: "ManropeRegular";
    src: local("ManropeRegular"),
        url("../fonts/Manrope-font/Manrope-Regular.ttf") format("opentype");
    font-weight: regular;
    font-weight: 400;
    font-style: normal;
}

body {
    font-family: ManropeRegular, sans-serif;
    font-weight: 400;
    font-style: normal;
    background: $color-white
}

input {
    font-family: ManropeRegular, sans-serif;
}

.hero-logo {
    text-align: center;
}

.hero-logo__image {
    width: 360px;
}

.login-background {
    // background: url(https://brave.com/static-assets/images/filler.png), linear-gradient(142deg, #3b2dcf 1%, #a91b78 100%);
    background: url('/assets/bgFilterImage.png'), linear-gradient(142deg, #3b2dcf 1%, #a91b78 100%);
    overflow: hidden;

    .login {
        height: 100vh;
    }

    .hero-logo {
        display: none;
    }
}

.loading {
    position: fixed;
    top: 0;
    outline: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: #000000AD;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}