.login {
    height: 97vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-box {
    display: flex;
    justify-content: center;
    margin: 10px;
    padding: 70px 40px;
    // font-family: sans-serif;
}

.login-box__form {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 220px;
}

.login-box__form__item {
    text-align: left;
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.login-box__form__item__input {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;

    &:focus-visible {
        outline: $color-grey auto 1px;
    }

}

.login-box__form__item__input-error {
    border: 1px solid $color-danger;

    &:focus-visible {
        outline: none
    }
}

.login-box__form__item__label {
    font-family: ManropeRegular, sans-serif;
    font-weight: 600;
    font-style: normal;
}

.login-box__form__forgot-password {
    margin-top: 0;
    text-align: right;
    font-size: 0.8125rem;
    color: $color-grey;
}

.login-box__form__forgot-password__link {
    color: $color-primary;
    text-decoration: none;
}

.login-box__form__button {
    width: 100%;
    padding: 10px;
    background-color: $color-primary;
    border: none;
    border-radius: 5px;

    &-disabled {
        background-color: $color-light-grey;
    }
}

.add-employee-box {
    background: $color-extra-light-grey;

    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    border-radius: 40px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;

    @include lg {
        width: 70%;
    }
}

.add-employee-box__article {}

.add-employee-box__article__columns {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.card-buddy {
    border-radius: 15px;
    box-shadow: 0 0 5px #e1e1e1;
    padding: 18px;
    background: #fff;
    margin-bottom: 20px;
}

.add-employee-box__article-columns {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 90%;
}

.add-employee-box__article__columns__item {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.add-employee-box__article__columns__item__input {
    padding: 10px 20px;
    border: 1px solid $color-light-grey;
    border-radius: 5px;
}

.add-employee-box__article__columns__item_message {
    margin-top: 0;
    font-size: 0.75rem;
    color: $color-grey
}

.add-employee-box__article__columns__category {
    width: 200px;
    border-radius: 15px;
    box-shadow: 0 0 5px #e1e1e1;
    padding: 18px;
    background: $color-white;
}

.add-employee-box__article__columns__category-title {
    width: 100%;
    text-align: center;
}

.add-employee-box__article__buddy-info {
    position: relative;
}

.add-employee-box__article__buddy-info__icon {
    width: 25px;
    position: absolute;
    top: 15px;
    right: -40px;
}

.filters__date {
    position: relative;
    text-align: right;
}

.filters__date__icon {
    cursor: pointer;
}

.filters__date__calendar {
    z-index: 5;
    position: relative;

    //----remove----
    box-shadow: 0px 0px 4px grey;
    background-color: white;
    //--------------
}